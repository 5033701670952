const routes = [
  {
    startsWith: '/',
    app: 'widgets',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
]

export default routes;